import Moment from "react-moment";
import React from "react";
import {
  GAME_TRANSACTIONS_LIST_SUCCESS,
  GAME_TRANSACTIONS_LIST_FAILURE,
  GAME_TRANSACTION_DETAIL_REQUEST,
  GAME_TRANSACTION_DETAIL_SUCCESS,
  GAME_TRANSACTION_DETAIL_FAILURE,
  GAME_REPLAY_SUCCESS,
  GAME_REPLAY_RESET,
  GAME_TRANSACTIONS_FILTERS_FAILURE,
  GAME_TRANSACTIONS_FILTERS_SUCCESS,
  STYLE_CURRENCY,
} from "../../../constants";
import Currency from "../../../../Layouts/Currency/Currency";
import { Box } from "@material-ui/core";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import Button from "@material-ui/core/Button";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import { PlayCircleFilledWhite } from "@material-ui/icons";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import Visibility from "@material-ui/icons/Visibility";
import Action from "../../../../Layouts/action/action";
import * as moment from "moment";

const getButtonView = (
  amountType,
  isPam,
  cb,
  walletId,
  walletConfirmId,
  isConfirm
) => {
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display={"flex"} alignItems={"center"}>
        {walletId}
      </Box>
      {walletId &&
        isPam &&
        amountType === "REAL_MONEY" &&
        walletConfirmId !== "MANUALLY_PAID" && (
          <Box>
            <Action
              onClick={() => cb(walletId, walletConfirmId, isConfirm)}
              color={"primary"}
              startIcon={<Visibility />}
              label={"button.view"}
              isPam={true}
              permission={"account_transaction"}
            />
          </Box>
        )}
    </Box>
  );
};

const getTransactionStatus = (status) => {
  switch (status) {
    case "RESERVED":
      return { label: "gameTransactionStatuses.reserved", id: "3" };
    case "ISSUED":
      return { label: "gameTransactionStatuses.issued", id: "3" };
    case "CLOSED":
      return { label: "gameTransactionStatuses.closed", id: "6" };
    case "LOSER":
      return { label: "gameTransactionStatuses.lost", id: "6" };
    case "REFUNDED":
      return { label: "gameTransactionStatuses.refunded", id: "6" };
    case "REFUNDING":
      return { label: "gameTransactionStatuses.refunding", id: "3" };
    case "VOIDED":
      return { label: "gameTransactionStatuses.voided", id: "5" };
    case "PENDING ":
      return { label: "gameTransactionStatuses.pending", id: "3" };
    case "WINNING":
      return { label: "gameTransactionStatuses.won", id: "2" };
    case "PAID":
      return { label: "gameTransactionStatuses.paid", id: "2" };
    case "ON_HOLD":
      return { label: "gameTransactionStatuses.onHold", id: "1" };
    case "NOT_PAID":
      return { label: "gameTransactionStatuses.notPaid", id: "5" };
    case "ON_HOLD_IN_PROGRESS":
      return { label: "gameTransactionStatuses.inProgress", id: "3" };
    case "ON_HOLD_APPROVED":
      return { label: "gameTransactionStatuses.approved", id: "2" };
    case "MANUALLY_PAID":
      return { label: "gameTransactionStatuses.manuallyPaid", id: "6" };
    case "VALIDATION_ERROR":
      return { label: "gameTransactionStatuses.error", id: "4" };
    case "NOT_EXECUTED":
      return { label: "gameTransactionStatuses.notExecuted", id: "6" };
    case "CANCELED":
      return { label: "gameTransactionStatuses.canceled", id: "8" };
    default:
      return { label: "unknown", id: "1" };
  }
};
export const labelPrefix = "label.individualplayer.gameTransactions.";
const amountTypeMap = {
  BONUS_BAG: <Translate needle={labelPrefix + "amountTypes.BONUS_BAG"} />,
  FPP: <Translate needle={labelPrefix + "amountTypes.FPP"} />,
  FREE_TICKET: <Translate needle={labelPrefix + "amountTypes.FREE_TICKET"} />,
  LOYALTY_POINT: (
    <Translate needle={labelPrefix + "amountTypes.LOYALTY_POINT"} />
  ),
  REAL_MONEY: <Translate needle={labelPrefix + "amountTypes.REAL_MONEY"} />,
  EXTERNAL_FREE_TICKET: (
    <Translate needle={labelPrefix + "amountTypes.EXTERNAL_FREE_TICKET"} />
  ),
  VOUCHER: <Translate needle={labelPrefix + "amountTypes.VOUCHER"} />,
  CREDITS: <Translate needle={labelPrefix + "amountTypes.CREDITS"} />,
  WALLET_BONUS: <Translate needle={labelPrefix + "amountTypes.WALLET_BONUS"} />,
  OFFLINE_PRIZE: (
    <Translate needle={labelPrefix + "amountTypes.OFFLINE_PRIZE"} />
  ),
  CASHLESS: <Translate needle={labelPrefix + "amountTypes.CASHLESS"} />,
  CASH: <Translate needle={labelPrefix + "amountTypes.CASH"} />,
  REINVESTMENT: <Translate needle={labelPrefix + "amountTypes.REINVESTMENT"} />,
  CURRENCY: <Translate needle={labelPrefix + "amountTypes.CURRENCY"} />,
  BONUS_CREDITS: (
    <Translate needle={labelPrefix + "amountTypes.BONUS_CREDITS"} />
  ),
  VOUCHER_CREDITS: (
    <Translate needle={labelPrefix + "amountTypes.VOUCHER_CREDITS"} />
  ),
  PERIODIC_WINNING: (
    <Translate needle={labelPrefix + "amountTypes.PERIODIC_WINNING"} />
  ),
};
const initialState = {
  loading: true,
  transactionDetails: {
    data: null,
    loading: true,
    fields: [
      { field: "gameSessionId" },
      {
        field: "gameSessionStartDate",
        render: (rowData) => (
          <DateFormatter
            format={"L LTS"}
            value={rowData["gameSessionStartDate"]}
          />
        ),
      },
      {
        field: "gameSessionEndDate",
        render: (rowData) => (
          <DateFormatter
            format={"L LTS"}
            value={rowData["gameSessionEndDate"]}
          />
        ),
      },
      {
        field: "totalPaidAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["totalPaidAmount"]}
          />
        ),
      },
      {
        field: "totalPurchaseAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["totalPurchaseAmount"]}
          />
        ),
      },
      {
        field: "totalPurchaseLoyaltyPoint",
        render: (rowData) => (
          <Currency
            currency={""}
            amount={rowData["totalPurchaseLoyaltyPoint"]}
          />
        ),
      },
      {
        field: "totalEarnedLoyaltyPoint",
        render: (rowData) => (
          <Currency currency={""} amount={rowData["totalEarnedLoyaltyPoint"]} />
        ),
      },
      {
        field: "currency",
        render: (rowData) => <CurrencyBadge currency={rowData["currency"]} />,
      },
      { field: "platformName" },
      { field: "gameName" },
      {
        field: "lastWagerDate",
        render: (rowData) => (
          <DateFormatter format={"L LTS"} value={rowData["lastWagerDate"]} />
        ),
      },
      {
        field: "lastWinningDate",
        render: (rowData) => (
          <DateFormatter format={"L LTS"} value={rowData["lastWinningDate"]} />
        ),
      },
      {
        field: "totalClaimingPrize",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["totalClaimingPrize"]}
          />
        ),
      },
    ],
    roundFields: (cb) => [
      {
        field: "startDate",
        render: (rowData) => (
          <DateFormatter format={"L LTS"} value={rowData["startDate"]} />
        ),
      },
      {
        field: "purchaseAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["purchaseAmount"]}
          />
        ),
      },
      {
        field: "purchaseLoyPoint",
        render: (rowData) => (
          <Currency currency={""} amount={rowData["purchaseLoyPoint"]} />
        ),
      },
      {
        field: "status",
        render: (rowData) => {
          const status = getTransactionStatus(rowData["status"]);
          return <BadgeStatus {...status} />;
        },
      },
      {
        field: "paymentGrossAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["paymentGrossAmount"]}
          />
        ),
      },
      {
        field: "paymentNetAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["paymentNetAmount"]}
          />
        ),
      },
      {
        field: "jackpotAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["jackpotAmount"]}
          />
        ),
      },
      {
        field: "taxableAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["taxableAmount"]}
          />
        ),
      },
      {
        field: "taxAmount",
        render: (rowData) => (
          <Currency
            currency={rowData["currency"]}
            amount={rowData["taxAmount"]}
          />
        ),
      },
      {
        field: "earnedLoyPoint",
        render: (rowData) => (
          <Currency currency={""} amount={rowData["earnedLoyPoint"]} />
        ),
      },
      {
        field: "roundId",
        title: labelPrefix + "gameReplay",
        render: (rowData) => (
          <Button
            onClick={() => cb(rowData)}
            color={"primary"}
            startIcon={<PlayCircleFilledWhite />}
          >
            <Translate needle={labelPrefix + "gameReplay"} />
          </Button>
        ),
      },
    ],
    transactionFields: (transactionType, cb, isPam) => {
      return transactionType === "PAYMENT"
        ? [
            {
              field: "walletReserveId",
              render: (rowData) => {
                return getButtonView(
                  rowData["amountType"],
                  isPam,
                  cb,
                  rowData["walletReserveId"],
                  rowData["walletConfirmId"]
                );
              },
            },
            { field: "amountType", lookup: amountTypeMap },
            { field: "externalTransactionId" },
            {
              field: "paymentStatus",
              render: (rowData) => {
                const paymentStatus = getTransactionStatus(
                  rowData["paymentStatus"]
                );
                return <BadgeStatus {...paymentStatus} />;
              },
            },
            {
              field: "grossAmount",
              render: (rowData) => (
                <Currency
                  currency={rowData["currency"]}
                  amount={rowData["grossAmount"]}
                />
              ),
            },
            {
              field: "netAmount",
              render: (rowData) => (
                <Currency
                  currency={rowData["currency"]}
                  amount={rowData["netAmount"]}
                />
              ),
            },
            {
              field: "jackpotAmount",
              render: (rowData) => (
                <Currency
                  currency={rowData["currency"]}
                  amount={rowData["jackpotAmount"]}
                />
              ),
            },
            {
              field: "walletConfirmId",
              render: (rowData) => {
                return getButtonView(
                  rowData["amountType"],
                  isPam,
                  cb,
                  rowData["walletReserveId"],
                  rowData["walletConfirmId"],
                  true
                );
              },
            },
            {
              field: "reserveDate",
              render: (rowData) => (
                <DateFormatter
                  format={"L LTS"}
                  value={rowData["reserveDate"]}
                />
              ),
            },
            {
              field: "confirmDate",
              render: (rowData) => (
                <DateFormatter
                  format={"L LTS"}
                  value={rowData["confirmDate"]}
                />
              ),
            },
            {
              field: "taxAmount",
              render: (rowData) => (
                <Currency
                  currency={rowData["currency"]}
                  amount={rowData["taxAmount"]}
                />
              ),
            },
            {
              field: "taxableAmount",
              render: (rowData) => (
                <Currency
                  currency={rowData["currency"]}
                  amount={rowData["taxableAmount"]}
                />
              ),
            },
          ]
        : [
            {
              field: "walletReserveId",
              render: (rowData) => {
                return getButtonView(
                  rowData["amountType"],
                  isPam,
                  cb,
                  rowData["walletReserveId"],
                  rowData["walletConfirmId"]
                );
              },
            },
            { field: "amountType", lookup: amountTypeMap },
            { field: "externalTransactionId" },
            {
              field: "amount",
              render: (rowData) => (
                <Currency
                  currency={
                    rowData["amountType"] === "LOYALTY_POINT"
                      ? ""
                      : rowData["currency"]
                  }
                  amount={rowData["amount"]}
                />
              ),
            },
            {
              field: "bonusAmount",
              render: (rowData) => (
                <Currency
                  currency={
                    rowData["amountType"] === "LOYALTY_POINT"
                      ? ""
                      : rowData["currency"]
                  }
                  amount={rowData["bonusAmount"]}
                />
              ),
            },
            {
              field: "walletConfirmId",
              render: (rowData) => {
                return getButtonView(
                  rowData["amountType"],
                  isPam,
                  cb,
                  rowData["walletReserveId"],
                  rowData["walletConfirmId"],
                  true
                );
              },
            },
            {
              field: "reserveDate",
              render: (rowData) => (
                <DateFormatter
                  format={"L LTS"}
                  value={rowData["reserveDate"]}
                />
              ),
            },
            {
              field: "confirmDate",
              render: (rowData) => (
                <DateFormatter
                  format={"L LTS"}
                  value={rowData["confirmDate"]}
                />
              ),
            },
            {
              field: "purchaseStatus",
              render: (rowData) => {
                const purchaseStatus = getTransactionStatus(
                  rowData["purchaseStatus"]
                );
                return <BadgeStatus {...purchaseStatus} />;
              },
            },
          ];
    },
  },
  transactionsList: {
    data: [],
    searchCriteria: [],
    columns: [
      {
        field: "gameSessionId",
        title: "label.individualplayer.gameTransactions.gameSessionId",
        sorting: false,
      },
      {
        field: "gameSessionStartDate",
        title: "label.individualplayer.gameTransactions.gameSessionStartDate",
        defaultSort: "asc",
        sortField: "gameSessionStart",
        default: true,
        render: (rowData) => {
          if (rowData["gameSessionStartDate"]) {
            return (
              <Moment format="L LTS">{rowData["gameSessionStartDate"]}</Moment>
            );
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["gameSessionStartDate"]) {
            return moment(rowData["gameSessionStartDate"]).format("L LTS");
          }
        },
      },
      {
        field: "gameSessionEndDate",
        title: "label.individualplayer.gameTransactions.gameSessionEndDate",
        sorting: false,
        render: (rowData) => {
          if (rowData["gameSessionEndDate"]) {
            return (
              <Moment format="L LTS">{rowData["gameSessionEndDate"]}</Moment>
            );
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["gameSessionEndDate"]) {
            return moment(rowData["gameSessionEndDate"]).format("L LTS");
          }
        },
      },
      {
        field: "platformName",
        sorting: false,
        title: "label.individualplayer.gameTransactions.platformName",
      },
      {
        field: "gameName",
        sorting: false,
        title: "label.individualplayer.gameTransactions.gameName",
        default: true,
      },
      {
        field: "totalPurchaseAmount",
        title: "label.individualplayer.gameTransactions.totalPurchaseAmount",
        nowrap: false,
        sorting: false,
        render: (rowData) => {
          if (rowData["totalPurchaseAmount"]) {
            return (
              <Currency
                amount={rowData["totalPurchaseAmount"]}
                currency={rowData["currency"]}
                justify={"flex-end"}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["totalPurchaseAmount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["totalPurchaseAmount"],
            };
          } else {
            return null;
          }
        },
      },
      {
        field: "totalPurchaseLoyaltyPoint",
        title:
          "label.individualplayer.gameTransactions.totalPurchaseLoyaltyPoint",
        nowrap: false,
        sorting: false,
        render: (rowData) => {
          if (rowData["totalPurchaseLoyaltyPoint"]) {
            return (
              <Currency
                currency={""}
                amount={rowData["totalPurchaseLoyaltyPoint"]}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["totalPurchaseLoyaltyPoint"]) {
            return {
              style: STYLE_CURRENCY,
              currency: "",
              value: rowData["totalPurchaseLoyaltyPoint"],
            };
          } else {
            return null;
          }
        },
      },
      {
        field: "totalPaidAmount",
        title: "label.individualplayer.gameTransactions.totalPaidAmount",
        nowrap: false,
        sorting: false,
        render: (rowData) => {
          if (rowData["totalPaidAmount"]) {
            return (
              <Currency
                amount={rowData["totalPaidAmount"]}
                currency={rowData["currency"]}
                justify={"flex-end"}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["totalPaidAmount"]) {
            return {
              style: STYLE_CURRENCY,
              currency: rowData["currency"],
              value: rowData["totalPaidAmount"],
            };
          } else {
            return null;
          }
        },
      },
      {
        field: "totalEarnedLoyaltyPoint",
        title:
          "label.individualplayer.gameTransactions.totalEarnedLoyaltyPoint",
        nowrap: false,
        sorting: false,
        render: (rowData) => {
          if (rowData["totalEarnedLoyaltyPoint"]) {
            return (
              <Currency
                currency={""}
                amount={rowData["totalEarnedLoyaltyPoint"]}
              />
            );
          }
        },
        getExportTypeFn: (rowData) => {
          if (rowData["totalEarnedLoyaltyPoint"]) {
            return {
              style: STYLE_CURRENCY,
              currency: "",
              value: rowData["totalEarnedLoyaltyPoint"],
            };
          } else {
            return null;
          }
        },
      },
    ],
  },
  gameReplayJson: {
    gameReplayData: [],
    gameReplayFields: [
      { field: "type" },
      { field: "channel" },
      { field: "duration" },
      {
        field: "multiplier",
        title: labelPrefix + "multiplier",
        render: (rowData) => {
          return <YesNoBadge value={rowData["multiplier"]} />;
        },
      },
      { field: "gameName" },
      {
        field: "transactionAmount",
        title: labelPrefix + "price",
        render: (rowData) => {
          return (
            <Currency
              currency={rowData["currency"]}
              amount={rowData["transactionAmount"]}
              platform="LOTTERY"
            />
          );
        },
      },
      {
        field: "transactionTime",
        render: (rowData) => {
          return (
            <DateFormatter
              format={"L LTS"}
              value={rowData["transactionTime"]}
            />
          );
        },
      },
      {
        field: "transactionPointAmount",
        title: labelPrefix + "paymentAmount",
        render: (rowData) => {
          if (rowData["transactionPointAmount"] > 0) {
            return (
              <Currency
                currency={rowData["currency"]}
                amount={rowData["transactionPointAmount"]}
                platform="LOTTERY"
              />
            );
          } else {
            return (
              <Currency
                currency={rowData["currency"]}
                amount={rowData["transactionAmount"]}
                platform="LOTTERY"
              />
            );
          }
        },
      },
      {
        field: "addonPlayed",
        render: (rowData) => {
          return <YesNoBadge value={rowData["addonPlayed"]} />;
        },
      },
      {
        field: "paymentType",
        title: labelPrefix + "paymentType",
        render: (rowData) => {
          if (rowData["transactionPointAmount"] > 0) {
            return "POINTS";
          }
        },
      },
    ],
    gameReplayColumns: [
      {
        field: "betType",
        title: labelPrefix + "betType",
      },
      {
        field: "quickpick",
        title: labelPrefix + "quickpick",
        render: (rowData) => {
          return <YesNoBadge value={rowData["quickpick"]} />;
        },
      },
      {
        field: "primarySelections",
        title: labelPrefix + "primarySelections",
        render: (rowData) => {
          return rowData["primarySelections"].join(", ");
        },
      },
      {
        field: "secondarySelections",
        title: labelPrefix + "secondarySelections",
        render: (rowData) => {
          return rowData["secondarySelections"].join(", ");
        },
      },
      {
        field: "quickPickCount",
        title: labelPrefix + "quickPickCount",
      },
      {
        field: "secondaryQuickPickCount",
        title: labelPrefix + "secondaryQuickPickCount",
      },
      {
        field: "primarySelectionsQuickpicked",
        title: labelPrefix + "primarySelectionsQuickpicked",
        render: (rowData) => {
          return <YesNoBadge value={rowData["primarySelectionsQuickpicked"]} />;
        },
      },
      {
        field: "secondarySelectionsQuickpicked",
        title: labelPrefix + "secondarySelectionsQuickpicked",
        render: (rowData) => {
          return (
            <YesNoBadge value={rowData["secondarySelectionsQuickpicked"]} />
          );
        },
      },
    ],
    loading: true,
  },
};

const GameTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GAME_TRANSACTIONS_FILTERS_SUCCESS: {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          searchCriteria: action.payload,
        },
      };
    }

    case GAME_TRANSACTIONS_FILTERS_FAILURE: {
      return {
        ...state,
        transactionsList: { ...state.transactionsList, searchCriteria: null },
      };
    }
    case GAME_TRANSACTIONS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        transactionsList: { ...state.transactionsList, data: action.payload },
      };
    }
    case GAME_TRANSACTIONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        transactionsList: { ...state.transactionsList, data: null },
      };
    }

    case GAME_TRANSACTION_DETAIL_REQUEST: {
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          data: null,
          loading: true,
        },
      };
    }
    case GAME_TRANSACTION_DETAIL_SUCCESS: {
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          data: action.payload,
          loading: false,
        },
      };
    }
    case GAME_TRANSACTION_DETAIL_FAILURE: {
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          data: null,
          loading: false,
        },
      };
    }

    case GAME_REPLAY_SUCCESS: {
      return {
        ...state,
        gameReplayJson: {
          ...state.gameReplayJson,
          gameReplayData: action.payload,
          loading: false,
        },
      };
    }

    case GAME_REPLAY_RESET: {
      return {
        ...state,
        gameReplayJson: {
          ...state.gameReplayJson,
          gameReplayData: [],
        },
      };
    }

    default:
      return state;
  }
};

export default GameTransactionsReducer;
